*:focus {
  outline: none;
}

.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.change-password-modal2 {
  width: 35%;
  height: 75%;
  display: flex;
  background: #ffff;
  position: absolute;
  top: 10%;
  left: 35%;
  border-radius: 25px;
  border: none;
  flex-direction: column;
}
.change-password-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 15%;
  font-family: "Montserrat";
  font-size: 3rem;
  line-height: 37px;
  color: #000000;
}
.change-pwd-heading {
  margin-left: 6%;
  font-weight: 600;
  font-size: 2.6rem;
}
.closebutton-password-modal {
  margin-right: 6%;
  width: 4rem;
  height: 4rem;
}
.change-password-modal-hr {
  display: flex;
  align-self: center;
  width: 97%;
  border: 1px solid #cfcfcf;
}
.change-password-modal-body {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.change-password-form {
  width: 80%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-evenly;
}
label.old-password-modal {
  width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.1rem;
  line-height: 41px;
  color: #000000;
}
.old-password-input {
  height: 9%;
  background: #f9fafd;
  border: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 22px;
  color: rgba(52, 52, 52, 0.5);
  padding-left: 30px;
}
label.new-password-form {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 41px;
  color: #000000;
}
input.new-password-input {
  height: 9%;
  background: #f9fafd;
  border: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 22px;
  color: rgba(52, 52, 52, 0.5);
  padding-left: 2rem;
}
label.confirm-password-form {
  height: 27px;
  font-weight: 600;
  font-size: 2.2rem;
}
input.confirm-password-input {
  height: 9%;

  background: #f9fafd;
  border: none;
  border-radius: 6px;

  font-weight: 500;
  font-size: 1.8rem;
  line-height: 22px;
  color: rgba(52, 52, 52, 0.5);
  padding-left: 2rem;
}
.change-password-modal-btn {
  height: 12%;
  background: #049f8f;
  border-radius: 5px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 22px;
  color: #ffffff;
}
