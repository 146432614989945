.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 12.5rem 0 12.5rem;
  background-color: #ffffff;
}

.loginbox-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-details {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
}

.error {
  color: red;
}

.login-title {
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 600;
}

.login-info {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
}

input:hover {
  cursor: pointer;
}

input[type="text"].login-input {
  border: none;
  border-bottom: 2px solid #ababab;
  padding-bottom: 1rem;
  font-size: 1.6rem;
  color: black;
  font-weight: 500;
}

input[type="text"].login-input:focus {
  padding-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #039f8f;
  line-height: -12px;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
}
input[type="password"].login-input {
  border: none;
  border-bottom: 2px solid #ababab;
  padding-bottom: 10px;
  font-size: 1.6rem;
  color: black;
  font-weight: 500;
}

input[type="password"]:focus {
  padding-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #039f8f;
  line-height: -12px;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
}

.show-password-toggle {
  display: flex;
  align-items: center;
  height: 32px;
}

input.toggle-password-btn {
  accent-color: #039f8f;
  height: 58%;
  width: 5%;
}

span.password-toggle-title {
  font-size: 1.4rem;
  font-weight: 400;
  padding-left: 3%;
}

.pwd-info {
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.remember-me_checkbox {
  accent-color: #039f8f;
}

.remember-me {
  display: flex;
  align-items: center;
  column-gap: 7px;
}
.remember-link {
  font-size: 1.4rem;
  color: #3c3c3c;
}
.forgot-pwd {
  font-size: 1.4rem;
  font-weight: 600;
  color: #039f8f;
}

.login-btn {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 18px 150px 15px 160px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
  color: white;
  background: #049f8f;
  cursor: pointer;
}
.login-btn:hover {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 18px 150px 15px 160px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 1rem;
  color: white;
  background: #049f8f;
  cursor: pointer;
}

/* || MOBILE */
@media screen and (max-width: 480px) {
  .Wrapper {
    width: 100vw;
    height: 100vh;
  }

  .login-box {
    width: 100%;
  }

  .loginbox-image {
    margin-bottom: 1rem;
  }

  .login-details {
    row-gap: 1.5rem;
    margin: 0 1.5rem 0 1.5rem;
  }

  .login-info {
    height: auto;
    row-gap: 1.5rem;
  }

  input[type="text"].login-input,
  input[type="password"].login-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pwd-info {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .remember-me {
    margin-bottom: 10px;
    justify-content: space-between;
  }

  button.login-btn {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1190px) {
  .Wrapper {
    width: 100vw;
    height: 100vh;
    margin: 0 2.5rem 0 2.5rem;
  }

  .login-box {
    width: 100%;
  }

  .loginbox-image {
    margin-bottom: 1rem;
  }

  .login-details {
    row-gap: 1.5rem;
  }
  .login-title {
    text-align: center;
  }

  .login-info {
    height: auto;
    row-gap: 1.5rem;
  }

  input[type="text"].login-input,
  input[type="password"].login-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pwd-info {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .remember-me {
    margin-bottom: 10px;
    justify-content: space-between;
  }

  button.login-btn {
    width: 100%;
  }
}
