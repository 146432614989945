.create-profile-modal {
  display: flex;
  width: 58%;
  height: 58%;
  position: absolute;
  left: 25%;
  top: 24%;
  /* display: flex; */
  background: #ffff;
  /* margin-left: 423px; */
  /* margin-top: 182px; */
  border-radius: 25px;
  border: none;
  flex-direction: column;
}
.create-profilemodal-title {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 20%;
  margin-inline: 0%;
  /* position: absolute; */
  /* width: 379px; */
  /* height: 35px; */
  /* margin-left: 140px; */
  /* margin-top: 59px; */
  /* font-family: "Montserrat"; */
  /* font-style: normal; */
  /* font-weight: 600; */
  font-size: 30px;
  /* line-height: 37px; */
  color: #000000;
}
.create-profile-head {
  margin-left: 11%;
  font-weight: 600;
  font-size: 3rem;
}
img.close-button-modal {
  margin-right: 6%;
}
hr.profile-modal-hr {
  /* position: absolute; */
  display: flex;
  align-self: center;
  width: 97%;
  height: 0px;
  /* margin-left: 5px; */
  /* margin-top: 143px; */
  border: 1px solid #cfcfcf;
}
.create-profile-modal-body {
  /* margin-top: 145px; */
  width: 100%;
  height: 75%;
  display: flex;
  /* position: absolute; */
}
.profile-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  /* height: 92%; */
  /* position: absolute; */
}
.profile-pic-modal {
  width: 60%;
  /* margin-left: 74.4px; */
  /* margin-top: 74px; */
}
.edit-profile-div {
  width: 55%;
  /* height: 85%; */
  /* margin-left: 450px; */
  display: flex;
  /* position: absolute; */
  flex-direction: column;
}
form.edit-profile {
  /* margin-top: 75px; */
  width: 90%;
  height: 100%;
  /* margin-left: 60px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
label.profile-form-modal-name {
  /* position: absolute; */
  width: -moz-fit-content;
  width: fit-content;
  /* height: 23px; */
  /* margin-top: 0px; */
  /* font-family: "Montserrat"; */
  /* font-style: normal; */
  /* font-weight: 600; */
  font-size: 22px;
  font-weight: 600;
  /* line-height: 27px; */
  /* color: #000000; */
}
input.profile-form-modal-nameinput {
  /* position: absolute; */
  width: 100%;
  height: 55px;
  /* margin-top: 35px; */
  background: #f9fafd;
  border-radius: 6px;
  border: none;
  /* font-family: "Montserrat"; */
  padding-left: 26px;
  font-size: 2.2rem;
  font-weight: 500;
}
label.profile-form-modal-email {
  /* position: absolute; */
  width: -moz-fit-content;
  width: fit-content;
  height: 23px;
  /* margin-top: 115px; */
  /* font-family: "Montserrat"; */
  /* font-style: normal; */
  font-weight: 600;
  font-size: 22px;
  /* line-height: 27px; */
  /* color: #000000; */
}
input.profile-form-modal-emailinput {
  /* position: absolute; */
  width: 100%;
  height: 55px;
  /* margin-top: 150px; */
  background: #f9fafd;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  padding-left: 26px;
  font-size: 2.2rem;
  font-weight: 500;
}
button.create-profile-form-modal-btn {
  /* position: absolute; */
  width: 100%;
  height: 55px;
  /* margin-left: 0px; */
  /* margin-top: 245px; */
  background: #049f8f;
  border-radius: 5px;
  border: none;
  /* font-family: "Montserrat"; */
  /* font-style: normal; */
  font-weight: 600;
  font-size: 1.8rem;
  /* line-height: 22px; */
  color: #ffffff;
}
