.recharts-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 65%;
  border-radius: 8px;
}
.chart-heading {
  width: 100%;
  height: 10%;
  color: #343434;
  font-weight: 600;
  font-size: 2rem;
  padding: 1% 0 0 2%;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 95%;
  box-sizing: border-box;
  margin-right: none;
  height: 100%;
}
/* .userinfo-and-date {
  height: 7%;
} */
.current-date {
  display: flex;
  /* height: 44%; */
  align-items: center;
  font-size: 1.6rem;
  color: #ada7a7;
}
.wrapper-box {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;
  height: 20%;
}
.graph {
  padding-top: 2.75rem;
  max-width: 95%;
  background: #ffffff;
  border-radius: 8px;
}
