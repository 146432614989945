select option {
  background-color: #f9fafd;
  color: #049f8f;
}
select option :hover {
  background-color: #039f8f;
  color: #636971;
  box-shadow: 8px;
}
.template-window {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 94%;
  height: 90%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}
.template-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  height: 10%;
  width: auto;
  padding: 0 3rem 0 3rem;
}
p.tot-results {
  width: -moz-fit-content;
  width: fit-content;
  height: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 16px;
  color: rgba(52, 52, 52, 0.6);
}

.add-btn {
  width: 9.6rem;
  height: 3.8rem;
  background: #049f8f;
  border-radius: 8px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.template-tab {
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  height: 90%;
}
.template-tab-main {
  display: flex;
  /* gap: 41%; */
  height: 5%;
  font-size: 1rem;
  justify-content: space-between;
  font-family: "Montserrat";
  color: #636971;
}
.template-tab-main-left {
  display: flex;
  align-items: center;
  padding-left: 2%;
  gap: 5.9rem;
}
p.templates-heading {
  font-size: 2rem;
  font-weight: 600;
  opacity: 0.8;
}
.template-tab-main-right {
  display: flex;
  align-items: center;
  width: 51%;
  /* gap: 34%; */
  justify-content: space-between;
}
.template-tab-main-right-start {
  display: flex;
  width: fit-content;
}
p.age-group {
  font-size: 2rem;
  font-weight: 600;
  opacity: 0.8;
}
.template-tab-main-right-end {
  display: flex;
  width: 60%;
  /* gap: 27%; */
  font-size: 2rem;
  justify-content: space-around;
}
p.view-heading {
  font-size: 2rem;
  font-weight: 600;
  opacity: 0.8;
}
p.edit-heading {
  font-size: 2rem;
  font-weight: 600;
  opacity: 0.8;
}
p.delete-heading {
  font-size: 2rem;
  font-weight: 600;
  opacity: 0.8;
}
hr.templates-tab-hr {
  width: 100%;
  height: 0px;
  /* margin-left: 1px; */
  /* margin-top: 30px; */
  border: 1px solid #e8e8e8;
}
.template-tab-content {
  display: flex;
  flex-direction: column;
  height: 85%;
  width: 100%;
  align-self: center;
  /* align-self: center; */
  /* margin-top: 30px; */
}
input.template-checkbox {
  accent-color: #039f8f;
}
.template1 {
  display: flex;
  align-items: center;
  height: 14.28%;
  margin-left: 0px;
  /* gap: 36%; */
  /* width: 1390px; */
  justify-content: space-between;
}
.template1:nth-child(even) {
  background: #f9fafd;
  border: 1px solid #f0f0f0;
}
.template-tab-content-left {
  display: flex;
  padding-left: 2%;
  gap: 5.9rem;
}
.template-name {
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 20px;
  /* letter-spacing: -0.5px; */
  opacity: 67%;
  color: #000000;
}
.template-tab-content-right {
  display: flex;
  justify-content: space-between;
  /* gap: 30%; */
  width: 50%;
}
.template-tab-content-right-start {
  width: fit-content;
  padding-left: 4%;
}
.age-grp {
  opacity: 60%;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 22px;
  color: #343434;
}
.template-tab-content-right-end {
  display: flex;
  justify-content: space-around;
  width: 61%;
  /* gap: 34%; */
}
.template-view {
  width: 2rem;
}
.template-edit {
  width: 2rem;
}
.template-delete {
  width: 2rem;
  color: #343434;
}

/* CSS for the view template modal */
.view-template-modal {
  position: absolute;
  width: 70%;
  height: 60%;
  top: 15%;
  left: 20%;
  background-color: #ffffff;
  border-radius: 25px;
}
.view-template-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.view-template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 17%;
}
.view-template-heading {
  margin-left: 5%;
  font-size: 2.2rem;
  font-weight: 600;
}
img.view-template-closebutton {
  margin-right: 5%;
  width: 4rem;
  height: 4rem;
}
.view-template-content-body {
  display: flex;
  width: 100%;
  height: 83%;
}
.view-template-leftwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 100%;
}
img.view-template-img {
  width: 70%;
  height: 70%;
}
.view-template-rightwrap {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 55%;
}
.view-template-right-content {
  display: flex;
  flex-direction: column;
  width: 92%;
  height: 66%;
}
.view-template-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 35%;
  font-size: 1.8rem;
}
.view-template-modal-heading {
  color: #a4a4a4;
  font-size: 2rem;
  font-weight: 600;
}
.view-template-title-content {
  font-weight: 500;
  font-size: 2.2rem;
}
.view-template-suitable-content {
  font-weight: 500;
  font-size: 2.2rem;
}
.view-description-content {
  font-weight: 500;
  font-size: 2.2rem;
}

/* css for the edit template modal  */
.edit-template-modal {
  position: absolute;
  width: 72%;
  height: 70%;
  top: 15%;
  left: 16%;
  background-color: #ffffff;
  border-radius: 25px;
}
.edit-template-modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.edit-template-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 15%;
}
.edit-template-modal-heading {
  margin-left: 5%;
  font-size: 2.2rem;
  font-weight: 600;
}
.edit-template-modal-closebutton {
  margin-right: 5%;
  width: 4rem;
  height: 4rem;
}
.edit-modal-content-body {
  display: flex;
  height: 85%;
}
.edit-modal-leftwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%;
  gap: 15%;
}
.edit-modal-image-upload-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 86%;
  width: 79%;
}
.edit-modal-image {
  width: 75%;
  height: 56%;
}
.edit-modal-rightwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;
}
form.edit-template-modal-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  height: 80%;
}
.edit-template-modal-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
}
.edit-template-modal-labelwrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 25%;
}
label.edit-template-heading {
  font-size: 2.2rem;
  font-weight: 600;
}
.edit-template-input {
  height: 60%;
  border-radius: 8px;
  border: none;
  background: #f9fafd;
  padding-left: 4%;
  color: rgba(52, 52, 52, 0.5);
  font-weight: 500;
  font-size: 2.2rem;
}
.button-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 20%;
  gap: 10%;
}
.edit-template-input {
  height: 60%;
  border-radius: 8px;
  border: none;
  background: #f9fafd;
  padding-left: 4%;
  color: rgba(52, 52, 52, 0.5);
  font-weight: 500;
  font-size: 2.2rem;
}
button.template-upload-btn {
  box-sizing: border-box;
  display: flex;
  align-self: center;
  width: 55%;
  padding: 16px;
  justify-content: space-around;
  border: 1px dashed #049f8f;
  border-radius: 12px;
  background: #ffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 27px;
  color: #049f8f;
}
button.edit-template-modal-save-btn {
  width: 25%;
  height: 5rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  background: #039f8f;
  color: #ffffff;
  font-size: 2rem;
}
button.edit-template-modal-cancel-btn {
  width: 25%;
  height: 5rem;
  font-weight: 600;
  border: 1px solid #049f8f;
  border-radius: 8px;
  background: #ffffff;
  color: #049f8f;
  font-size: 2rem;
}

/* CSS for the delete Template Modal */

.delete-template-modal {
  position: absolute;
  top: 25%;
  left: 35%;
  width: 30%;
  height: 20%;
  background: #ffffff;
  border-radius: 15px;
}
.delete-template-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.delete-template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35%;
}
.delete-template-heading {
  margin-left: 5%;
  font-size: 1.8rem;
  font-weight: 600;
}

.delete-template-closeButton {
  margin-right: 4%;
  width: 3rem;
  height: 3rem;
}

.delete-template-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 3%;
  width: 100%;
  height: 65%;
}

.delete-template-button-section {
  display: flex;
  justify-content: space-between;
  width: 40%;
  height: 40%;
  margin-right: 6%;
}

.delete-template-button {
  width: 42%;
  background: #049f8f;
  color: white;
  border: none;
  font-size: 1.7rem;
  border-radius: 8px;
  font-weight: 500;
}
