.welcome-page {
  display: flex;
  background-color: #ffffff;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.left-container {
  flex: 2;
  position: relative;
}

.over-text {
  position: absolute;
  top: 30%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  font-weight: 600;
  color: #ffffff;
}

.login-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.right-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

/* Mobile Width */
@media screen and (max-width: 480px) {
  .welcome-page {
    justify-content: center;
    flex-flow: row wrap;
  }
  .login-img {
    display: none;
  }
  .left-container {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .right-container {
    display: flex;
  }
}

/* Larger Screen Sizes */
@media only screen and (min-width: 481px) and (max-width: 1190px) {
  .welcome-page {
    margin: 0;
    width: 100vw;
    height: 100vh;
  }
  .login-img {
    display: none;
  }
  .left-container {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  .right-container {
    width: 100%;
    height: 100%;
  }
}
