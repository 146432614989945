.category-window {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 94%;
  height: 90%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}
.category-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem 0 3rem;
  margin-top: 0px;
  height: 10%;
  width: auto;
}
p.tot-results {
  /* position: relative; */
  /* left: 40px; */
  right: 0;
  /* top: 30px; */
  bottom: 0;
  width: 150px;
  height: 20px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-size: 1.6rem;
  line-height: 16px;
  color: rgba(52, 52, 52, 0.6);
}

.category-add-btn {
  width: 9.6rem;
  height: 3.8rem;
  background: #049f8f;
  border-radius: 8px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.category-content-right {
  display: flex;
  justify-content: space-around;
  width: 29%;
}
.category-tab {
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  height: 90%;
}
.category-tab-main {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  color: #636971;
  height: 5%;
  width: 100%;
  font-family: "Montserrat";
}
.category-tab-main-left-wrap {
  display: flex;
  align-items: center;
  padding-left: 2%;
  gap: 3rem;
}
.category-tab-main-right-wrap {
  display: flex;
  justify-content: space-around;
  width: 40%;
  /* gap: 9rem; */
}
p.title {
  opacity: 0.8;
  font-weight: 600;
  font-size: 2rem;
}
p.category-name {
  width: max-content;
  height: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-size: 1.8rem;
  line-height: 20px;
  letter-spacing: -0.5px;
  opacity: 67%;
  color: #000000;
}
p.category-view {
  opacity: 0.8;
  font-weight: 600;
  font-size: 2rem;
}
p.edit {
  opacity: 0.8;
  font-weight: 600;
  font-size: 2rem;
}
p.category-name {
  width: max-content;
  height: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 20px;
  letter-spacing: -0.5px;
  opacity: 67%;
  color: #000000;
}
p.delete {
  opacity: 0.8;
  font-weight: 600;
  font-size: 2rem;
}
hr.category-tab-hr {
  width: 100%;
  height: 0px;
  /* margin-top: 30px; */
  border: 1px solid #e8e8e8;
}
.category-tab-content {
  display: flex;
  flex-direction: column;
  height: 85%;
  width: 100%;
  align-self: center;
  /* margin-top: 30px; */
}
.category-content-left {
  display: flex;
  gap: 3rem;
}
.category1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14.28%;
}
.category-content-left {
  display: flex;
  gap: 3rem;
  padding-left: 2%;
  height: 32%;
}
.category-content-right {
  display: flex;
  justify-content: space-around;
  width: 39%;
  /* gap: 10.5rem; */
}

input.category-checkbox {
  accent-color: #049f8f;
}
.category1:nth-child(even) {
  background: #f9fafd;
  border: 1px solid #f0f0f0;
}

p.category-name {
  color: #000;
  font-family: "Montserrat";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  letter-spacing: -0.5px;
  line-height: 20px;
  opacity: 50%;
  width: -webkit-max-content;
  width: max-content;
}

.category-content-view {
  width: 2rem;
}
.category-edit-icon {
  width: 2.5rem;
}
.delete-section-category {
  display: flex;
  justify-content: center;
  width: 4.5rem;
}

.deletecategory {
  width: 2.5rem;
}

/* from here all the modals in the category page are being designed */

/* this is for the design of the catrgory view modal */
.View-Category-Modal {
  position: absolute;
  width: 35%;
  height: 30%;
  top: 24%;
  left: 34%;
  background-color: #ffffff;
  border-radius: 25px;
}
.category-detail-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.category-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 39%;
  font-size: 2rem;
}
span.category-detail-heading {
  margin-left: 5%;
  font-weight: 600;
  font-size: 2.4rem;
}
img.view-category-closebutton {
  margin-right: 5%;
}
.category-detail-content-body {
  display: flex;
  height: 61%;
}
.categoryview-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
}
.category-view-modal-img {
  border-radius: 50%;
  width: 70%;
  height: 70%;
}
.categoryview-details {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}
.categorydetail-wrapper {
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  width: 90%;
  height: 45%;
  gap: 0%;
}
span.Category-heading {
  font-size: 2rem;
  font-weight: 600;
  color: #a4a4a4;
}
span.category-title {
  font-size: 2.2rem;
  font-weight: 500;
}

/* this is for the design of editCategoryModal */
.edit-category-modal {
  position: absolute;
  width: 26%;
  height: 51%;
  top: 24%;
  left: 40%;
  background-color: #ffffff;
  border-radius: 25px;
}
.category-edit-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.category-edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20%;
  font-size: 2rem;
}
span.category-edit-heading {
  margin-left: 6%;
  font-weight: 600;
  font-size: 2.6rem;
}
img.edit-category-closebutton {
  margin-right: 6%;
}
hr.edit-category-hr {
  display: flex;
  align-self: center;
  width: 98%;
}
.category-edit-content {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
form.edit-category-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  width: 80%;
  height: 90%;
}
/* .category-image-input {
  width: 44%;
  height: 44%;
} */
.edit-category-image-input {
  width: 165px;
  height: 165px;
  border-radius: 50%;
}
.edit-category-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 27%;
  width: 100%;
}
label.edit-category-name {
  font-size: 2.2rem;
  font-weight: 600;
}
input.edit-category-input {
  width: 100%;
  height: 50%;
  outline: none;
  background: #f9fafd;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 500;
}
.edit-category-button-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 15%;
}
button.save-edit-category {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  border: none;
  border-radius: 8px;
  background: #049f8f;
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
}
button.cancel-edit-category {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  border: 1px solid #049f8f;
  border-radius: 8px;
  background: #ffffff;
  color: #049f8f;
  font-size: 1.6rem;
  font-weight: 600;
}
