.user-display {
  display: flex;
  width: 100%;
  /* height: 65%; */
  /* margin-left: 5rem; */
  /* margin-top: 1.6rem; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  /* line-height: 4rem; */
}
