.add-category-window {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 95%;
  /* margin: 6rem 0 0 8rem; */
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}
.add-category-heading {
  display: flex;
  align-items: center;
  width: 100%;
  height: 15%;
  padding-left: 5.4rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  color: #343434;
}
hr.add-category {
  display: flex;
  align-self: center;
  width: 99%;
  height: 0px;
  border: 1px solid rgba(52, 52, 52, 0.2);
}
.add-category-body {
  display: flex;
  height: 100%;
  width: 100%;
}

.icon-category {
  height: 100%;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}
img.category-img {
  width: 90%;
  height: 60%;
}

.category-img {
  cursor: pointer;
}
.right-side-content {
  display: flex;
  height: 100%;
  width: 55%;
  justify-content: center;
  align-items: center;
}
.add-title {
  display: flex;
  width: 90%;
  flex-direction: column;
  row-gap: 9rem;
}
form.add-title-form {
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;
}
.title-heading {
  width: 55px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 27px;
  color: #000000;
}
.title-input {
  height: 7rem;
  background: #f9fafd;
  border: none;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  padding-left: 25px;
  line-height: 27px;
  color: #343434;
}
.btn-class {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3.1rem;
}
button.save-btn {
  width: 20%;
  height: 50px;
  background: #049f8f;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: none;
}
button.cancel-btn {
  box-sizing: border-box;
  width: 20%;
  height: 50px;
  border: 1px solid #049f8f;
  border-radius: 8px;
  background: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.9rem;
  text-align: center;
  color: #049f8f;
}
