.users-wrapper {
  padding: 2% 2% 2% 2%;
  background: #ffffff;
  border-radius: 0.8rem;
  box-sizing: border-box;
  display: flex;
  width: 20vw;
  /* height: 16rem; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #000000;
  font-size: 2.4rem;
  font-family: "Montserrat";
}
.wrapper-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.users-wrapper:hover {
  background: #e4f2e5;
  border: 0.2rem solid #039f8f;
}
.users-wrapper:hover circle,
.users-wrapper:hover path,
.users-wrapper:hover rect {
  fill: #049f8f;
  stroke: #ffffff;
}
#my-svg:hover circle {
  fill: #049f8f;
}

#my-svg:hover path {
  stroke: #ffffff;
  fill: #ffffff;
}
#my-svg:hover rect {
  stroke: #ffffff;
}
.svg-container {
  display: flex;
  width: 29%;
  justify-content: flex-end;
}
.name-container {
  color: #272425;
  opacity: 0.7;
  font-size: 1.6rem;
  font-weight: 500;
}
.value-container {
  font-weight: 700;
  font-size: 2.4rem;
}
.growth-container {
  display: flex;
  color: #272425;
  opacity: 0.7;
  font-size: 1.5rem;
  line-height: 1.9rem;
  padding-bottom: 0px;
}
