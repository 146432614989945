.addtemplate-window {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 95%;
  height: 95%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}
.addtemplate-heading {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 0 0 4rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 27px;
  color: #343434;
}
hr.addtemplate-hr {
  display: flex;
  align-self: center;
  width: 99%;
  border: 1px solid rgba(52, 52, 52, 0.2);
}
.addtemplate-body {
  width: 100%;
  height: 89%;
  display: flex;
  flex-direction: row;
}
.addtemplate-upload {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
img.addtemplate-imgupload {
  box-sizing: border-box;
  height: 45%;
}
button.addtemplate-upload-btn {
  box-sizing: border-box;
  display: flex;
  align-self: center;
  width: 75%;
  padding: 16px;
  justify-content: space-around;
  border: 1px dashed #049f8f;
  border-radius: 12px;
  background: #ffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 27px;
  color: #049f8f;
}
.addtemplate-form-side {
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.addtemplate-form {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 17%;
}
label.addtemplate-title {
  width: 55px;
  height: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.9);
}
.addtemplate-titleinput {
  height: 60%;
  background: #f9fafd;
  border-radius: 6px;
  border: none;
  padding-left: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(52, 52, 52, 0.5);
}
label.addtemplate-category {
  height: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.9);
}
.addtemplate-categoryinput {
  height: 60%;
  background: #f9fafd;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(52, 52, 52, 0.5);
}
label.addtemplate-suitaiblity {
  width: 145px;
  height: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.9);
}
.addtemplate-suitable-input {
  height: 60%;
  background: #f9fafd;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(52, 52, 52, 0.5);
}
label.addtemplate-description {
  width: 145px;
  height: 27px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.9);
}
.addtemplate-description-inp {
  height: 60%;
  background: #f9fafd;
  border-radius: 6px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 27px;
  color: rgba(52, 52, 52, 0.5);
}
.addtemplate-btn {
  display: flex;
  justify-content: flex-end;
  gap: 3.1rem;
  width: 100%;
  height: 10%;
}
.addtemplate-savebtn {
  width: 25%;
  background: #049f8f;
  border-radius: 8px;
  font-family: "Montserrat";
  color: #ffff;
  font-size: 2rem;
  font-weight: 600;
  border: none;
}
.addtemplate-cancelbtn {
  box-sizing: border-box;
  width: 25%;
  border: 1px solid #049f8f;
  border-radius: 8px;
  font-family: "Montserrat";
  font-size: 1.9rem;
  font-weight: 600;
  background: #ffff;
  color: #049f8f;
}
