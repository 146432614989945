.fluid {
  height: 100vh;
}
.main {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100%;
  background-color: #f6f6f6;
}

.nav-container {
  display: flex;
  width: 26%;
}
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.children {
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-box {
  box-sizing: border-box;
  width: 100%;
  height: 7.5%;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
}
