.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 7.5%;
  background: #ffffff;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 25%);
  color: #343434;
  /* box-shadow: 0px 1px 4px rgb(0 0 0 / 25%); */
}
input.search-panel {
  position: relative;
  width: 50%;
  height: 70%;
  /* margin-top: 1.2rem; */
  background: #f6f6f6;
  border-radius: 5rem;
  border: none;
  font-family: "Montserrat";
  font-weight: 300;
  padding-left: 2.5rem;
  font-size: 1.6rem;
}

*:focus {
  outline: none;
}
