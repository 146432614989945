.users-window {
  display: flex;
  flex-direction: column;
  width: 94%;
  height: 90%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 12px;
}
.window-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem 0 3rem;
  margin-top: 0px;
  height: 10%;
  width: auto;
}
.tot-results {
  left: 40px;
  right: 0;
  top: 30px;
  bottom: 0;
  width: 104px;
  height: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-size: 1.1rem;
  line-height: 16px;
  color: rgba(52, 52, 52, 0.6);
}
.total-results {
  width: -moz-fit-content;
  display: flex;
  align-self: center;
  width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: rgba(52, 52, 52, 0.6);
  height: 21px;
}
.users-tab {
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  height: 90%;
}
.user-tab-main {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  height: 5%;
  color: #636971;
  /* gap: 69%; */
  width: 100%;
  font-family: "Montserrat";
  /* outline: 2px solid black; */
}

.user-tab-main-left-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-tab-main-right-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
  height: 100%;
  /* gap: 4.5rem; */
  margin-right: 3%;
}
.users-tabcolumn {
  margin-left: 30px;
  font-weight: 600;
  font-size: 2rem;
  color: #636971;
  opacity: 0.8;
}
.users-tabstatus {
  opacity: 0.8;
  font-weight: 600;
  font-size: 2rem;
  color: #636971;
}
.users-tabview {
  opacity: 0.8;
  font-weight: 600;
  font-size: 2rem;
  color: #636971;
}
.users-tabdelete {
  opacity: 0.8;
  font-weight: 600;
  font-size: 2rem;
  color: #636971;
}
.user-tab-hr {
  /* position: absolute; */
  width: 100%;
  height: 0px;
  /* margin-left: 1px; */
  /* margin-top: 35px; */
  border: 1px solid #e8e8e8;
}
.user-tab-content {
  display: flex;
  flex-direction: column;

  height: 85%;
  width: 100%;
  align-self: center;
}
.users-checkbox {
  accent-color: #049f8f;
}

.user1 {
  display: flex;
  /* gap: 57%; */
  justify-content: space-between;
  align-items: center;
  height: 14.28%;
  color: #636971;
}
.user-content-left {
  display: flex;
  width: 25%;
  padding-left: 3%;
  align-items: center;
  gap: 3rem;
}
.user-view-img {
  /* position: absolute; */
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.username {
  /* position: absolute; */
  /* width: 200px; */
  height: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 20px;
  /* letter-spacing: -0.5px; */
}
.user-content-right {
  display: flex;
  justify-content: space-between;
  width: -moz-fit-content;
  width: 31%;
  margin-right: 3%;
}
.user-status {
  width: 2rem;
}
.userview {
  width: 25px;
}
.delete-section-user {
  display: flex;
  width: 4.3rem;
}
.deleteuser {
  width: 25px;
}
.user1:nth-child(even) {
  background: #f9fafd;
  border: 1px solid #f0f0f0;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  /* margin-top: 2rem; */
}
.pagination-btn {
  width: 10rem;
  height: 3.8rem;
  background: #049f8f;
  border-radius: 8px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

/*css for user detail modal */
.user-detail-modal {
  position: absolute;
  width: 54%;
  height: 50%;
  top: 20%;
  left: 25%;
  background-color: #ffffff;
  border-radius: 25px;
}
.user-detail-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.user-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
}
.user-detail-heading {
  margin-left: 6%;
  font-size: 3rem;
  font-weight: 600;
}
.profile-close-button {
  margin-right: 5%;
  width: 4rem;
  height: 4rem;
}
.profile-detail-hr {
  display: flex;
  align-self: center;
  width: 98%;
  border: 1px solid #cfcfcf;
}
.profile-detail-content {
  display: flex;
  align-self: center;
  width: 98%;
  height: 80%;
}
.profile-left {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-evenly;
  width: 43%;
  height: 100%;
}
.profile-image {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  height: 60%;
  /* gap: 3.5rem; */
}
.profile-wrapper {
  width: 60%;
  height: 100%;
  /* border: 0.7rem solid #049f8f;
  border-radius: 50%; */
}
.profile-name {
  font-size: 2.4rem;
  display: flex;
  justify-content: center;
  font-weight: 500;
}
.profile-vertical-line {
  display: flex;
  align-self: center;
  width: 0.1rem;
  background-color: #cfcfcf;
  height: 80%;
}

.profile-right {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.profile-info-wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-evenly;
  width: 80%;
  height: 100%;
}
.right-profile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 18%;
  font-size: 2.2rem;
}
.info-title-profile {
  font-weight: 600;
  color: #a4a4a4;
  font-size: 2rem;
}
.info-display-profile {
  font-size: 2.2rem;
  font-weight: 500;
}
/* Css for the delete user modal */

.user-delete-modal {
  position: absolute;
  top: 25%;
  left: 37%;
  width: 30%;
  height: 20%;
  background: #ffffff;
  border-radius: 15px;
}
.delete-user-modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.delete-user-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35%;
}
.delete-user-modal-heading {
  margin-left: 5%;
  font-size: 1.8rem;
  font-weight: 600;
}
.user-delete-modal-close {
  margin-right: 4%;
}
img.user-delete-modal-close-icon {
  width: 3rem;
  height: 3rem;
}
.user-delete-modal-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 3%;
  width: 100%;
  height: 65%;
}
.user-delete-modal-button-section {
  display: flex;
  justify-content: space-between;
  width: 40%;
  height: 40%;
  margin-right: 6%;
}
.user-deletemodal-button {
  width: 42%;
  background: #049f8f;
  color: white;
  border: none;
  font-size: 1.7rem;
  border-radius: 8px;
  font-weight: 500;
}

/* @media screen and (max-height: 700px) {
  .user-tab-main-right-wrap {
    gap: 5.5rem;
  }
} */
