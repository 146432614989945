@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
  box-sizing: border-box;
  font-family: "Montserrat";
  outline: none;
}

html {
  margin: 0;
  padding: 0;
  font-size: 10px;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

@media screen and (max-height: 700px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 1100px) {
  html {
    font-size: 8px;
  }
}
