.navigation {
  display: flex;
  flex-direction: column;
  /* max-width: 401px; */
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 12%);
}
.top-panel {
  width: 100%;
  height: 33.33%;
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
}
/* img.top-logo {
  width: 15.3rem;
  height: 6.352rem;
} */
.top-logo {
  width: 100%;
  /* padding-top: 4.5rem;
  padding-bottom: 4rem; */
}

.user-profile-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 63%;
}
.user-image-display {
  width: 100%;
  height: 70%;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* gap: 1.5rem; */
  color: #343434;
  transition: 0.3s;
}

img.user-img {
  width: 100%;
  height: 100%;
}
.user-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
}
.admin-acc {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
}
.email {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
}
html {
  background: #ebebeb;
}
.mid-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 0px;
  width: 100%;
  height: 33.33%;
  font-size: 1.9rem;
}
.tab-icon {
  width: 2.9rem;
  height: 2.5rem;
}
.dash-small {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  column-gap: 20%;
  height: 24%;
  margin-bottom: 1px;
  padding: 7% 0px 7% 13%;
  font-size: 1.8rem !important;
  font-family: "Montserrat";
  color: #343434;
  font-weight: 500;
  opacity: 0.8;
}
img.dash-icon {
  margin-left: 4.3rem;
}

.active-tab {
  background: #e4f2e5;
  color: #049f8f;
  width: 100%;
  height: 24%;
  font-weight: 600;
  border-right: 5px solid #039f8f;
}
h3.dash-head {
  width: 11.4rem;
  height: 2.7rem;
  margin-left: 4.5rem;
  margin-top: 2.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: #343434;
}
.users-small {
  display: flex;
  height: 7rem;
}

img.user-icon {
  margin-left: 4.3rem;
  width: 2.5rem;
}

* {
  text-decoration: none;
}
h3.user-head {
  width: 5.1rem;
  height: 2.2rem;
  margin-left: 5.3rem;
  margin-top: 2.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #343434;
  opacity: 90%;
}
.category-small {
  display: flex;
  height: 7rem;
}
img.category-icon {
  margin-left: 4.3rem;
  width: 2.5rem;
}
h3.category-head {
  width: 5.1rem;
  height: 2.2rem;
  margin-left: 5rem;
  margin-top: 2.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #343434;
  opacity: 90%;
}
.video-small {
  display: flex;
  height: 7rem;
}
img.video-icon {
  margin-left: 4.3rem;
  width: 2.5rem;
}
h3.video-head {
  width: 5.1rem;
  height: 2.2rem;
  margin-left: 5rem;
  margin-top: 2.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #343434;
  opacity: 90%;
}
hr {
  width: 100%;
  /* color: #EBEBEB; */
  border: 1px solid #ebebeb;
}
.bottom-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 33.33%;
}
.gen-head {
  /* width: 101px; */
  height: 15%;
  display: flex;
  align-items: center;
  padding-left: 12%;
  /* height: 24px; */
  /* margin-left: 46px; */
  /* margin-top: 15px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  /* line-height: 24px; */
  color: rgba(52, 52, 52, 0.6);
}

.setting-pane {
  height: 25%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
}
.settings-icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
}
.setting-icon {
  width: 2.7rem;
  height: 2.7rem;
}
.setting-head {
  width: 60%;
  display: flex;
  align-items: center;
  /* margin-left: 50px; */
  /* margin-top: 25px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: rgba(52, 52, 52, 0.9);
}
.support-pane {
  display: flex;
  height: 25%;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 15px; */
}
.support-icon-div {
  display: flex;
  width: 35%;
  justify-content: center;
}
.support-icon {
  width: 2.7rem;
  height: 2.7rem;
}
.support-head {
  display: flex;
  width: 60%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 22px;
  color: #343434;
  opacity: 0.9;
}
.logout-box {
  /* display: flex; */
  width: auto;
  height: 24%;
  /* height: 7.4rem; */
  box-sizing: border-box;
  /* margin-top: 2.5rem; */
  display: flex;
  justify-content: space-between;
  background: #049f8f;
}
.logout-icon-div {
  display: flex;
  justify-content: center;
  width: 35%;
}

img.logout-icon {
  width: 3.5rem;
}

.logout-btn {
  display: flex;
  align-self: center;
  width: 60%;
  /* height: 74px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 16px;
  background: #049f8f;
  border: none;
  color: #e4f2e5;
}

@media (max-height: 855px) {
  .user-image-display {
    height: 50%;
  }
  .user-info {
    height: 35%;
    justify-content: space-between;
  }
  .user-name {
    font-size: 20px;
  }
  .admin-acc {
    font-size: 1.2rem;
  }
  .email {
    font-size: 1.2rem;
  }
  .top-logo {
    width: 75%;
  }
  /* img.top-logo {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
  } */
}
@media (max-width: 992px) {
  .top-logo {
    width: 80%;
  }
}
